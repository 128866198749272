import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createBrowserHistory, History } from "history";
import * as React from "react";
import { Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

export type ProviderType = {
  children: React.ReactNode;
};

type CustomRouterProps = {
  history: History;
  children: React.ReactNode;
};

const CustomRouter: React.FC<CustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export const HISTORY = createBrowserHistory() as History;

export const MTDemoProviders: React.FC<ProviderType> = (props) => {
  return (
    <CustomRouter history={HISTORY}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Auth0Provider
          domain={getReactAppEnvVar("auth0_domain")}
          clientId={getReactAppEnvVar(`auth0_client_id`)}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/app/sessions`,
            audience: getReactAppEnvVar("auth0_audience"),
          }}
        >
          <GraphQLProvider>{props.children}</GraphQLProvider>
        </Auth0Provider>
      </QueryParamProvider>
    </CustomRouter>
  );
};

export const getReactAppEnvVar = (name: string) => {
  const value = process.env[`REACT_APP_${name.toUpperCase()}`];
  if (!value) throw Error(`missing env var - ${name}`);
  return value;
};

type GraphQLProviderProps = {
  children: React.ReactNode;
};

const GraphQLProvider: React.FC<GraphQLProviderProps> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = React.useState<string | null>(null);

  const loadAccessToken = async () => {
    try {
      setAccessToken(await getAccessTokenSilently());
    } catch (error) {
      setAccessToken("");
    }
  };

  React.useEffect(() => {
    loadAccessToken();
  }, []);

  const client = new ApolloClient({
    uri:
      (process.env.REACT_APP_API_BASE_URL ??
        `https://${window.location.hostname}/api`) + "/graphql",
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    connectToDevTools: false,
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
