import { Container } from "@mui/material";
import * as React from "react";
import { Header } from "./Header";

export const DefaultLayout: React.FC<{ children: React.ReactElement }> = (props) => {
  return <>
    <Header />
    <Container>{props.children}</Container>
  </>
}
