import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProfileHeader } from "../Profile";
import MirrorTabLogo from "../../mt_logo_512x512.png";

export const Header: React.FC = () => {
  const pages = [
    {
      title: "Sessions",
      path: "/app/sessions",
    },
    {
      title: "Sites",
      path: "/app/sites",
    },
    {
      title: "Profile",
      path: "/app/profile",
    },
  ];

  const navigate = useNavigate();

  return (
    <AppBar position="static" style={{ background: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              style={{ background: "white", paddingTop: 10 }}
              height={45}
              src={MirrorTabLogo}
              alt="MirrorTab Logo"
            />
          </Typography>
          {pages.map((page) => (
            <MenuItem
              id={`nav_link_${page.title.toLowerCase()}`}
              style={{ color: "black" }}
              key={page.title}
              onClick={() => navigate(page.path)}
            >
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          ))}
          <ProfileHeader />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
