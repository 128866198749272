import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs } from "@mui/material";

type ViewWithBreadcrumbsProps = {
  breadcrumbs: React.ReactNode[];
  children: React.ReactNode;
};
export const ViewWithBreadcrumbs: React.FC<ViewWithBreadcrumbsProps> = (
  props
) => {
  return (
    <Box>
      <Box>
        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
          {props.breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box>{props.children}</Box>
    </Box>
  );
};
