import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Paper,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import React from "react";
import { ViewWithBreadcrumbs } from "./ViewWithBreadcrumbs";
import moment from "moment";

type SiteWorkspaceDetailProps = {};

const SITES = "Sites";
const SETTINGS = "Settings";

type SiteWorkspaceDetailTab = typeof SITES | typeof SETTINGS;

export const SiteWorkspaceDetail: React.FC<SiteWorkspaceDetailProps> = () => {
  const navigate = useNavigate();
  const { siteWorkspaceData, loading, workspaceId } = useSiteWorkspaceContext();
  const [activeTab, setActiveTab] =
    React.useState<SiteWorkspaceDetailTab>(SITES);

  if (loading) return <CircularProgress />;
  if (!siteWorkspaceData) throw Error("error");

  const sitesTab = (
    <Box>
      <Box display="flex" style={{ justifyContent: "space-between" }}>
        <Box display={"flex"}>
          <Button
            variant="contained"
            style={{ marginTop: "30px", height: "50px" }}
            size="large"
            onClick={() =>
              navigate(`/app/sites/workspaces/${workspaceId}/create-site`)
            }
          >
            Create new site
          </Button>
        </Box>
      </Box>
      {!siteWorkspaceData.siteWorkspace.sites.length && (
        <Alert severity="error">
          There are no sites for this workspace. Create one above.
        </Alert>
      )}
      {siteWorkspaceData.siteWorkspace.sites.map((site) => (
        <Box marginBottom={2}>
          <Card>
            <Typography fontSize={20}>
              {site.title} ({site.type})
            </Typography>
            <Typography>{site.description}</Typography>
            <Button
              onClick={() =>
                navigate(`/app/sites/workspaces/${workspaceId}/site/${site.id}`)
              }
              variant="outlined"
              size="small"
            >
              Manage
            </Button>
          </Card>
        </Box>
      ))}
    </Box>
  );

  const settingsTab = (
    <Box>
      <Box>
        <Box display={"flex"}>
          <h2>Workspace Settings</h2>
        </Box>
        <Box>
          <h3>Members</h3>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Added to Workspace</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Role</strong>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {siteWorkspaceData.siteWorkspace.userAssociations.map(
                (userAssociation) => (
                  <TableRow>
                    <TableCell>{userAssociation.user.emailAddress}</TableCell>
                    <TableCell>
                      {moment(parseInt(userAssociation.created, 10)).fromNow()}
                    </TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>
                      <Button
                        disabled
                        variant="outlined"
                        size="small"
                        color="warning"
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );

  return (
    <ViewWithBreadcrumbs
      breadcrumbs={[
        <Link underline="none" color="inherit">
          <h1>Sites</h1>
        </Link>,
        <Link
          underline="always"
          color="inherit"
          href={`/app/sites/workspaces/${workspaceId}`}
        >
          <h1>{siteWorkspaceData.siteWorkspace.title}</h1>
        </Link>,
      ]}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab value={SITES} label="Sites" />
          <Tab value={SETTINGS} label="Settings" />
        </Tabs>
      </Box>
      <Box>
        {activeTab === SITES && sitesTab}
        {activeTab === SETTINGS && settingsTab}
      </Box>
    </ViewWithBreadcrumbs>
  );
};
