import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ViewWithBreadcrumbs } from "./ViewWithBreadcrumbs";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import { useParams } from "react-router-dom";
import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import { InfoTable } from "./Debug";
import moment from "moment";
import { useConfigContext } from "../webappConfig";

type SiteDetailProps = {};

const SITE_QUERY = gql(`
query SiteQuery($id: String!) {
  site(id: $id) {
    id
    created
    slug
    stats {
      hits
    }
    type
    title
    scale
    description
    domains {
      id
      domainName
    }
    siteWorkspace {
      id
      slug
      title
      userAssociations {
        id
        created
        user {
          id
          emailAddress
        }
      }
    }
    ... on HostedSite {
      currentLiveDeployment {
        id
        created
        bundle {
          id
          hash
        }
      }
    }
    ... on ProxySite {
      url
    }
  }
}
`);

export const SiteDetail: React.FC<SiteDetailProps> = (props) => {
  const {
    webAppConfig: { environment },
  } = useConfigContext();
  const ENVIRONMENT = environment();
  const { siteWorkspaceData, loading, workspaceId } = useSiteWorkspaceContext();
  const { siteId } = useParams();
  const { data: siteData, loading: siteLoading } = useQuery(SITE_QUERY, {
    variables: {
      id: siteId ?? "",
    },
    skip: !siteId,
    fetchPolicy: "no-cache",
  });

  if (!siteWorkspaceData || loading || siteLoading) return <CircularProgress />;

  if (!siteData) return <span />;

  return (
    <ViewWithBreadcrumbs
      breadcrumbs={[
        <Link underline="none" color="inherit">
          <h1>Sites</h1>
        </Link>,
        <Link
          underline="none"
          color="inherit"
          href={`/app/sites/workspaces/${workspaceId}`}
        >
          <h1>{siteWorkspaceData.siteWorkspace.title}</h1>
        </Link>,
        <Link
          underline="always"
          color="inherit"
          href={`/app/sites/workspaces/${workspaceId}/site/${siteId}`}
        >
          <h1>{siteData?.site.title}</h1>
        </Link>,
      ]}
    >
      <Box>
        <h3>Basic Information</h3>
        <Card style={{ width: 600 }}>
          <InfoTable
            rows={[
              ["ID", <>{siteData?.site?.id}</>],
              [
                "Created",
                <>{moment(parseInt(siteData?.site.created, 10)).fromNow()}</>,
              ],
              ["Title", siteData.site.title],
              ["Description", siteData.site.description],
              ["Slug", siteData.site.slug],
              ["Scale", siteData.site.scale ?? "0"],
              [
                "Type",
                <Box>
                  <Box>{siteData.site.type}</Box>
                  <Box>
                    {siteData.site.__typename == "HostedSite" && (
                      <Card style={{ width: 600 }}>
                        <InfoTable
                          rows={[
                            [
                              "Latest deployment",
                              <>
                                {siteData?.site?.currentLiveDeployment?.created
                                  ? moment(
                                      parseInt(
                                        siteData?.site?.currentLiveDeployment
                                          ?.created ?? "",
                                        10
                                      )
                                    ).fromNow()
                                  : "(none)"}
                              </>,
                            ],
                          ]}
                        />
                      </Card>
                    )}

                    {siteData.site.__typename == "ProxySite" && (
                      <Card style={{ width: 600 }}>
                        <InfoTable
                          rows={[["Proxy URL", <>{siteData?.site?.url}</>]]}
                        />
                      </Card>
                    )}
                  </Box>
                </Box>,
              ],
            ]}
          />
        </Card>
      </Box>
      <Box>
        <h3>Domains</h3>
        <Button variant="contained">Add new domain</Button>
        <TableContainer component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Type</strong>
              </TableCell>
              <TableCell>
                <strong>URL</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell>Default (created by MirrorTab)</TableCell>
            <TableCell>
              <Link
                href={`https://${siteData.site.slug}.${ENVIRONMENT}.mirrortab.com`}
              >
                https://{siteData.site.slug}.{ENVIRONMENT}.mirrortab.com
              </Link>
            </TableCell>
            <TableCell>
              <Button variant="outlined" color="error" size="small" disabled>
                Delete
              </Button>
            </TableCell>
          </TableRow>
        </TableContainer>
      </Box>
    </ViewWithBreadcrumbs>
  );
};
