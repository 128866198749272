import { useQuery } from "@apollo/client";
import React from "react";
import { gql } from "../__generated__";
import {
  GetSiteWorkspaceQuery,
  GetSiteWorkspacesQuery,
} from "../__generated__/graphql";
import { useParams } from "react-router-dom";

type SiteWorkspaceContext = {
  loading?: boolean;
  siteWorkspaceData?: GetSiteWorkspaceQuery | null;
  workspaceId?: string | null;
  refetchWorkspaces?: () => any;
  refetchWorkspace?: () => any;
  workspaceListLoading?: boolean;
  workspaceListData?: GetSiteWorkspacesQuery | null;
};

const defaultSiteWorkspaceContext: SiteWorkspaceContext = {};

const GET_SITE_WORKSPACE = gql(`
query GetSiteWorkspace($id: String!) {
    siteWorkspace(id: $id) {
        id
        title
        userAssociations {
          id
          created
          user {
            id
            emailAddress
            firstName
            lastName
          }
        }
        sites {
          id
          stats {
            hits
          }
          slug
          type
          title
          description
          domains {
            id
            domainName
          }
          ... on HostedSite {
            currentLiveDeployment {
              id
              bundle {
                id
                hash
              }
            }
          }
          ... on ProxySite {
            url
          }
        }
    }
}`);

const GET_SITE_WORKSPACES_QUERY = gql(`
query GetSiteWorkspaces {
    viewer {
        user {
        firstName
        lastName
        emailAddress
        id
        siteWorkspaceUserAssociations {
            id
            created
            siteWorkspace {
              id
              title
            }
        }
      }
    }
}`);

const SiteWorkspaceContext = React.createContext(defaultSiteWorkspaceContext);

type SiteWorkspaceContextProviderProps = {
  children: React.ReactNode;
};

export const SiteWorkspaceContextProvider: React.FC<
  SiteWorkspaceContextProviderProps
> = (props) => {
  const { workspaceId } = useParams();

  const {
    data,
    refetch: refetchWorkspace,
    error,
    loading,
  } = useQuery(GET_SITE_WORKSPACE, {
    variables: { id: workspaceId ?? "" },
    skip: !workspaceId,
  });

  const {
    data: workspaceListData,
    loading: workspaceListLoading,
    refetch: refetchWorkspaces,
  } = useQuery(GET_SITE_WORKSPACES_QUERY, { fetchPolicy: "no-cache" });

  const siteWorkspaceContextValue: SiteWorkspaceContext = {
    siteWorkspaceData: data,
    loading,
    workspaceId,
    refetchWorkspaces,
    refetchWorkspace,
    workspaceListLoading,
    workspaceListData,
  };

  return (
    <SiteWorkspaceContext.Provider value={siteWorkspaceContextValue}>
      {props.children}
    </SiteWorkspaceContext.Provider>
  );
};

export const useSiteWorkspaceContext = () =>
  React.useContext(SiteWorkspaceContext);
