import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { gql } from "../__generated__";
import { useMutation } from "@apollo/client";
import React from "react";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";
import { useNavigate } from "react-router-dom";
import { InfoTable } from "./Debug";
import { filterNotNull, isValidUrl } from "../lib/helper";

type SiteCreateProps = {};

const CREATE_HOSTED_SITE_MUTATION = gql(`
mutation CreateHostedSiteMutation($title: String!, $description: String, $workspaceId: String!, $scale: Int!) {
  createHostedSite(input: { title: $title, description: $description, workspaceId: $workspaceId, scale: $scale}) {
    created
    site {
      id
      title
      description
      scale
      siteWorkspace {
        id
      }
    }
    error
  }
}
`);

const CREATE_PROXY_SITE_MUTATION = gql(`
mutation CreateProxySiteMutation($title: String!, $description: String, $workspaceId: String!, $proxyUrl: String!, $scale: Int!) {
  createProxySite(input: { title: $title, description: $description, workspaceId: $workspaceId, proxyUrl: $proxyUrl, scale: $scale}) {
    created
    site {
      id
      title
      description
      scale
      siteWorkspace {
        id
      }
    }
    error
  }
}
`);

const HOSTED = "hosted";
const PROXY = "proxy";
type SiteType = typeof HOSTED | typeof PROXY;

export const SiteCreate: React.FC<SiteCreateProps> = (props) => {
  const navigate = useNavigate();
  const { workspaceId, refetchWorkspace } = useSiteWorkspaceContext();
  const [createHostedSiteMutation, {}] = useMutation(
    CREATE_HOSTED_SITE_MUTATION
  );
  const [createProxySiteMutation, {}] = useMutation(CREATE_PROXY_SITE_MUTATION);
  const [saving, setSaving] = React.useState(false);
  const [siteType, _setSiteType] = React.useState<SiteType | null>(HOSTED);

  const [title, _setTitle] = React.useState("");
  const [description, _setDescription] = React.useState("");
  const [proxyUrl, _setProxyUrl] = React.useState("");
  const [scale, _setScale] = React.useState(0);

  const [scaleError, setScaleError] = React.useState<string | null>("");
  const [titleError, setTitleError] = React.useState<string | null>("");
  const [descriptionError, setDescriptionError] = React.useState<string | null>(
    ""
  );
  const [proxyUrlError, setProxyUrlError] = React.useState<string | null>("");

  const setSiteType = (value: SiteType) => {
    _setSiteType(value);
    switch (value) {
      case HOSTED:
        setProxyUrlError(null);
        setProxyUrl("");
        break;
      default:
        break;
    }
  };

  const setScale = (value: number) => {
    _setScale(value);

    if (value < 0 || value > 20) {
      setScaleError("Value must be between 0 and 20");
    } else {
      setScaleError(null);
    }
  };

  const setProxyUrl = (value: string) => {
    _setProxyUrl(value);

    if (!value || !isValidUrl(value)) {
      setProxyUrlError("Proxy URL is required and must be a URL");
    } else {
      setProxyUrlError(null);
    }
  };

  const setTitle = (value: string) => {
    _setTitle(value);

    if (!value) {
      setTitleError("Title is required");
    } else {
      setTitleError(null);
    }
  };

  const setDescription = (value: string) => {
    _setDescription(value);

    if (!value) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError(null);
    }
  };

  const submit = async () => {
    if (!siteType) throw Error("no site type");
    if (!title) throw Error("no title");
    if (!workspaceId) throw Error("no workspace id");

    switch (siteType) {
      case HOSTED:
        setSaving(true);
        const createdHostedSite = await createHostedSiteMutation({
          variables: {
            title,
            description,
            workspaceId,
            scale,
          },
        });

        if (createdHostedSite.data?.createHostedSite.created) {
          setSaving(false);
          refetchWorkspace?.();
          navigate(
            `/app/sites/workspaces/${workspaceId}/site/${createdHostedSite.data.createHostedSite.site?.id}`
          );
        }
        break;
      case PROXY:
        setSaving(true);
        const createdProxySite = await createProxySiteMutation({
          variables: {
            title,
            description,
            workspaceId,
            proxyUrl,
            scale,
          },
        });

        if (createdProxySite.data?.createProxySite.created) {
          setSaving(false);
          refetchWorkspace?.();
          navigate(
            `/app/sites/workspaces/${workspaceId}/site/${createdProxySite.data.createProxySite.site?.id}`
          );
        }
        break;
      default:
        throw Error("invalid site type");
    }
  };

  const createDisabled =
    saving ||
    !title ||
    !description ||
    !siteType ||
    !!titleError ||
    !!descriptionError ||
    !!scaleError ||
    (siteType == PROXY && !!proxyUrlError && siteType == PROXY && !proxyUrl);

  const buttonStyle = {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px",
    marginRight: "10px",
    marginBottom: "10px",
    width: "150px",
  };

  return (
    <Box marginBottom="20px">
      {" "}
      {/* Added margin */}
      <RadioGroup
        row
        value={siteType}
        onChange={(e) => setSiteType(e.target.value as SiteType)}
      >
        <FormControlLabel
          value={HOSTED}
          control={<Radio color="primary" />}
          label="Hosted site"
          style={{
            ...buttonStyle,
            border: `1px solid ${siteType === HOSTED ? "#000" : "#ccc"}`,
            marginBottom: "10px", // Added margin
          }}
        />
        <FormControlLabel
          value={PROXY}
          control={<Radio color="primary" />}
          label="Mirror an existing site"
          style={{
            ...buttonStyle,
            border: `1px solid ${siteType === PROXY ? "#000" : "#ccc"}`,
            marginBottom: "10px", // Added margin
          }}
        />
      </RadioGroup>
      {siteType == HOSTED && "Creating a hosted site"}
      {siteType == PROXY && "Creating a mirrored site"}
      <InfoTable
        rows={filterNotNull([
          [
            "Site title",
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Site title"
              error={!!titleError}
              label={titleError}
              fullWidth
              margin="dense" // Added margin
            />,
          ],
          [
            "Site description",
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Site description"
              error={!!descriptionError}
              label={descriptionError}
              fullWidth
              margin="dense" // Added margin
            />,
          ],
          [
            "Scale",
            <TextField
              value={scale}
              type="number"
              onChange={(e) => setScale(parseInt(e.target.value))}
              placeholder="Scale"
              error={!!scaleError}
              label={scaleError}
              fullWidth
              margin="dense" // Added margin
            />,
          ],
          siteType == PROXY
            ? [
                "Proxy URL",
                <Box marginBottom="10px">
                  {" "}
                  {/* Added margin */}
                  <TextField
                    value={proxyUrl}
                    onChange={(e) => setProxyUrl(e.target.value)}
                    placeholder="Proxy URL"
                    error={!!proxyUrlError}
                    label={proxyUrlError}
                    fullWidth
                    margin="dense" // Added margin
                  />
                </Box>,
              ]
            : null,
        ])}
      />
      <Box>
        <Button
          size="large"
          disabled={createDisabled}
          variant="contained"
          onClick={() => submit()}
        >
          {saving ? <CircularProgress /> : "Create Site"}
        </Button>
      </Box>
    </Box>
  );
};
