import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./LoginButton";
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Profile: React.FC = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [accessToken, setAccessToken] = React.useState("");

  const loadToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      loadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated && user ? (
    <Box marginTop={2}>
      <img src={user.picture} alt={user.name} />
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <TextField fullWidth multiline value={accessToken} />
    </Box>
  ) : (
    <LoginButton />
  );
};

export const ProfileHeader: React.FC = () => {
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();

  const [, setAccessToken] = React.useState("");

  const loadToken = async () => {
    const token = await getAccessTokenSilently();
    setAccessToken(token);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      loadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isLoading && !isAuthenticated) {
    return <LoginButton />;
  }

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            pading: 0,
            margin: 0,
            bgcolor: "rgb(8, 92, 37)",
          }}
        >
          {user?.email?.substring(0, 2).toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        <MenuItem
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};
