import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { gql } from "../__generated__";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useSiteWorkspaceContext } from "./SiteWorkspaceContext";

type SiteWorkspaceCreateProps = {};

const CREATE_WORKSPACE_MUTATION = gql(`
  mutation CreateWorkspace($title: String!) {
    createSiteWorkspace(input: { title: $title }) {
      created
      workspace {
        id
        title
      }
      error
    }
  }
`);

export const SiteWorkspaceCreate: React.FC<SiteWorkspaceCreateProps> = () => {
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const [newWorkspaceTitle, setNewWorkspaceTitle] = React.useState("");
  const [createWorkspaceMutation] = useMutation(CREATE_WORKSPACE_MUTATION);
  const { refetchWorkspaces } = useSiteWorkspaceContext();

  const createWorkspace = async () => {
    setSaving(true);
    const data = await createWorkspaceMutation({
      variables: {
        title: newWorkspaceTitle,
      },
    });
    setSaving(false);

    if (!data.data?.createSiteWorkspace.created)
      throw Error("site workspace not created");

    await refetchWorkspaces?.();
    navigate(
      `/app/sites/workspaces/${data.data?.createSiteWorkspace.workspace?.id}`
    );
  };

  return (
    <Box>
      <Box>
        <TextField
          value={newWorkspaceTitle}
          placeholder="New Workspace title"
          onChange={(e) => setNewWorkspaceTitle(e.target.value)}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          disabled={saving}
          onClick={() => createWorkspace()}
        >
          {saving ? <CircularProgress /> : "Create Workspace"}
        </Button>
      </Box>
    </Box>
  );
};
