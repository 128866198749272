import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import {
  SiteWorkspaceContextProvider,
  useSiteWorkspaceContext,
} from "./SiteWorkspaceContext";
import { useNavigate } from "react-router-dom";

type SiteContainerProps = {
  children?: React.ReactNode;
};

export const SiteContainer: React.FC<SiteContainerProps> = (props) => {
  return (
    <SiteWorkspaceContextProvider>
      <SiteContainerContents {...props} />
    </SiteWorkspaceContextProvider>
  );
};

const SiteContainerContents: React.FC<SiteContainerProps> = (props) => {
  const navigate = useNavigate();
  const {
    workspaceId,
    loading: workspaceLoading,
    workspaceListData,
    workspaceListLoading,
  } = useSiteWorkspaceContext();

  if (workspaceListLoading) return <CircularProgress />;

  const workspaceSelector = (
    <Select
      value={workspaceId}
      onChange={(e) =>
        navigate(`/app/sites/workspaces/${e.target.value as string}`)
      }
    >
      {workspaceListData?.viewer.user.siteWorkspaceUserAssociations.map(
        (siteWorkspaceUserAssociation) => (
          <MenuItem
            key={siteWorkspaceUserAssociation.siteWorkspace.id}
            value={siteWorkspaceUserAssociation.siteWorkspace.id}
          >
            {siteWorkspaceUserAssociation.siteWorkspace.title}
          </MenuItem>
        )
      )}
    </Select>
  );

  const body = workspaceLoading ? <CircularProgress /> : props.children;

  return (
    <>
      <Card>
        <Box>
          {workspaceSelector}{" "}
          <Button
            variant="outlined"
            onClick={() => navigate("/app/sites/create-workspace")}
          >
            Create Workspace
          </Button>
        </Box>
      </Card>
      {body}
    </>
  );
};

const NoWorkspaceSelected: React.FC = (props) => {
  return <Alert severity="error">No workspace selected</Alert>;
};
